.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F8EABA;
    /* Semi-transparent white */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* Ensure pre-loader is on top of content */
}

.preloader .animation {
    width: 50px;
    height: 50px;
    border: 5px solid #5d5d5d;
    border-radius: 50%;
    border-top-color: #FF7A00;
    /* Create spinning effect */
    animation: spin 1s linear infinite;
}

.preloader.hidden {
    display: none;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#banner {
    padding: 120px 0px;
    background: url('../assets/media/banner-bg.png');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#banner h1 {
    color: #FF7A00;
    font-size: 50px;
    letter-spacing: 4px;
    -webkit-text-stroke-width: 1px;
    /* Safari/Chrome */
    -webkit-text-stroke-color: black;
    /* Safari/Chrome */
    text-stroke-width: 1px;
    text-stroke-color: black;
}

#banner .info-box {
    background-color: #fff;
    padding: 60px;
    border-radius: 20px;
    border: 3px solid #000;
    box-shadow: -4px 4px 0px rgba(255, 122, 0, 1), 0px 4px 0px rgba(255, 122, 0, 1);
    /* left shadow, bottom shadow */
}

#banner .banner-img img {
    max-width: 500px;
    position: absolute;
    bottom: 0;
    right: 0;
}

#separator {
    padding: 10px;
    background-color: #fff;
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
    box-shadow: 0px 2px 0px rgba(255, 122, 0, 1),
        /* Top shadow */
        0px -2px 0px rgba(255, 122, 0, 1);
    /* Bottom shadow */
}

#presale .data {
    padding: 0px;
}

#presale .fieldset {
    border: 3px solid #000;
    border-radius: 12px;

}

#presale h6 {
    margin: 0px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;

}

#presale .right {
    padding: 10px 50px;
    border-left: 3px solid;
    background-color: #FFCB9B;
    border-radius: 12px;
}

#presale .value {
    margin-left: 20px;
}

#presale p {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0px;
    padding: 0px;
    letter-spacing: 3px;
}


#presale button {
    border: none;
    background: none;
}

#about,
#presale,
#nft {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0px;
}

/* #about h2 {
    padding-top: 20px;
} */

#about .container,
#presale .box,
#nft .box {
    padding: 40px;
    max-width: 800px;
    background-color: #fff;
    border-radius: 20px;
    border: 3px solid #000;
    box-shadow: 4px 4px 0px rgba(255, 122, 0, 1), 0px 4px 0px rgba(255, 122, 0, 1);
}

#about img {
    margin-left: -80px;
}


#fomonomics {
    padding: 60px 0px;
}

#fomonomics .section-title {
    text-align: center;
    margin-bottom: 60px;
}

#fomonomics .text-box {
    padding: 40px;
    background-color: #fff;
    border-radius: 20px;
    border: 3px solid #000;
    box-shadow: 4px 4px 0px rgba(255, 122, 0, 1), 0px 4px 0px rgba(255, 122, 0, 1);
    margin-bottom: 20px;
}

#fomonomics .text-box p {
    /* font-family: "JazzyRabbit", sans-serif; */
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0px;
    padding: 0px;
    letter-spacing: 3px;
}

#fomonomics h3 {
    margin-bottom: 0px;
}

#fomonomics .copy-btn {
    padding: 0px;
    font-size: 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

#fomonomics .btn-fomo {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    background-color: #FF7A00;
    border: 3px solid #000;
    border-radius: 12px;
    padding: 10px 45px;
    margin: 5px;
    box-shadow: 3px 3px 0px rgba(255, 122, 0, 1), 0px 5px 0px rgba(255, 122, 0, 1);
}

.tooltip-show,
.presale-tooltip-show {
    display: block;
    position: absolute;
    top: -50px;
    /* Adjust the position as needed */
    left: 50%;
    /* Adjust the position as needed */
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 999;
}

.tooltip-hide,
.presale-tooltip-hide {
    display: none;
}

#nft {
    padding-bottom: 120px;
}

#nft h2 {
    margin-bottom: 0px;
}

#nft h2 span {
    color: #FF7A00;
    font-size: 2.5rem;
}

#nft .box img.bunny {
    max-width: 100px;
}


/* Media query for medium devices */
@media (max-width: 1200px) {
    #banner .banner-img img {
        max-width: 100%;
        position: static;
    }

    #presale .value {
        margin-left: 15px;
    }
}

/* Media query for tablets */
@media (max-width: 768px) {

    .navbar-collapse {
        margin-top: 20px;
    }

    #banner {
        padding: 60px 0px;
    }

    #banner h1 {
        color: #FF7A00;
        font-size: 40px;
        letter-spacing: 2px;

    }

    #about img {
        margin-left: 0px;
    }

    #fomonomics .section-title {
        text-align: center;
        margin-bottom: 20px;
    }

    #fomonomics h3.token {
        font-size: 14px;
    }

    #presale .right {
        padding: 10px;
    }

    #presale .value {
        margin-left: 10px;
    }

}

/* Media query for tablets */
@media (max-width: 576px) {

    #banner h1 {
        color: #FF7A00;
        font-size: 28px;
        letter-spacing: 2px;

    }

    #about .container {
        padding: 20px;

    }

    #banner .info-box {
        background-color: #fff;
        padding: 60px 20px 0px 20px;
    }

    #banner .banner-img img {
        max-width: 100%;
    }

    #presale .fieldset {
        padding: 10px;
    }

    #presale h6.token {
        font-size: 14px;
    }

    #nft h2 span {
        color: #FF7A00;
        font-size: 1.8rem;
    }

    #presale .value {
        margin-left: 0px;
    }

}

@media (max-width: 768px) {
    .text-and-image {
        flex-direction: column-reverse;
    }

}

#presale .countdown{
    text-align: center;
    display: flex;
    justify-content: center;
}

#presale .countdown-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#presale .countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    font-weight: bold;
}

#presale .countdown .value {
    font-size: 24px;
    margin-left: 0px !important;
    padding: 6px;
    border: 2px solid #000;
    border-radius: 5px;
}

#presale .countdown .label {
    font-size: 14px;
}

#featured{
    padding: 60px 0px;
}

#featured .f-logo{
    max-width: 100%;
    margin: 5px;
}