#header{
    background-color: #F8EABA;
    border-bottom: 3px solid #000;
    box-shadow: 0px 5px 0px rgba(255, 122, 0, 1);
}

#header nav .navbar-brand img{
    max-width: 80px;
}

#header .nav-item{
    margin: 0px 10px;
}

#header nav a{
    /* font-family: "JazzyRabbit", sans-serif !important; */
    text-transform: uppercase;
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

#header .social img{
    max-width: 40px;
}
