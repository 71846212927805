@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@font-face {
  font-family: 'KaijuMonsterG';
  src: url('./assets/fonts/kaiju.ttf') format('truetype');
}

@font-face {
  font-family: 'JazzyRabbit';
  src: url('./assets/fonts/JazzyRabbit.ttf') format('truetype');
}

body {
  background-color: #F8EABA;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'KaijuMonsterG', sans-serif;
  text-transform: uppercase;
}

span,
p,
a,button, ul li {
  color: #000;
  font-family: 'JazzyRabbit', sans-serif;
  letter-spacing: 2px;
  font-size: 18px;
  line-height: 24px;
}

ul{
  padding-left: 1rem;
}
