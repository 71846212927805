footer{
    padding: 60px 0px;
    border-top: 3px solid #000;
    box-shadow: 0px -3px 3px rgba(255, 122, 0, 1); /* Top shadow */
}

footer h2{
    color: #FF7A00;
    font-size: 50px;
    letter-spacing: 4px;
    -webkit-text-stroke-width: 1px;
    /* Safari/Chrome */
    -webkit-text-stroke-color: black;
    /* Safari/Chrome */
    text-stroke-width: 1px;
    text-stroke-color: black;
}

footer a.social img{
    max-width: 40px;
}

footer .footer-logo{
    max-width: 200px;
    margin-top: -100px;
}

footer .copyright{
    border-top: 1px solid;
    margin-top: 20px;
}
footer .copyright p{
    color: #000;
    /* font-family: "JazzyRabbit", sans-serif; */
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
    padding: 0px;
    letter-spacing: 3px;
}


/* Media query for tablets */
@media (max-width: 576px) {

    footer .footer-logo{
        max-width: 150px;
        margin-top: -60px;
    }

    footer h2{  
        font-size: 40px;
        letter-spacing: 2px;
    }

    footer a.social img{
        max-width: 30px;
    }
    

}